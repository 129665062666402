@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* font-family: 'Poppins', sans-serif; */
.bg_Dashboar {
  font-family: "sans-serif";
  overflow-x: hidden;
  height: 100% !important;
  background-image: url("/src/assets/images/back-bg.jpg") !important;
  background-size: cover;
  background-position: center;
  
}

@media screen and (max-width: 600px) {
    .bg_usser_main{
        background-image: url("/src/assets/images/back-bg.jpg") !important;
        height: 70vh !important;
        opacity: .5;
    }
    .bg_usser{
        margin-bottom: 20px !important;
        margin-top: 20px;
    }
    .main_sels{
        background: rgb(19,0,71);
    }
    
}
.front_ip {
  background: linear-gradient(180.09deg, #162660 -10.43%, #1E74B1 19.57%, #296acd 47.66%, #1E74B1 74.16%, #1E3E6E 103.48%) !important;
}
.main_div_her_login_red {
  /* background-color: #0B0B47 !important; */
  height: 100% !important;
  background: linear-gradient(180.09deg, #162660 -10.43%, #1E74B1 19.57%, #296acd 47.66%, #1E74B1 74.16%, #1E3E6E 103.48%);
}
.main_div_her_login_red {
  /* background-color: #0B0B47 !important; */
  height: 100% !important;
  background: linear-gradient(180.09deg, #162660 -10.43%, #1E74B1 19.57%, #296acd 47.66%, #1E74B1 74.16%, #1E3E6E 103.48%);
}
/* add the backgrond image  */

.App_Main {
  /* background-color: #123752; */
  /* background-color: #123752 !important; */
 
  width: 100%;
  /* height: auto; */
  /* padding-bottom: 110px; */
}

/* End add the backgrond image  */

/* utils classes  */

.containerFluid_padding {
  padding-right: 9px !important;
  padding-left: 9px !important;
}

/* End utils classes  */


 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap'); 

/* *{
    font-family: 'Poppins', 'Inter', sans-serif;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    scroll-behavior: smooth;
} */
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
body{
    margin: 0px;
    padding: 0px;
    font-family: 'Rubik', sans-serif;
    background-color: white !important;
    background-repeat: no-repeat;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    color: white;
    font-weight: 500;
}
.App{
    /* height: 100vh; */
}
.hidden-for-area{
    display: none;
    visibility: hidden;
}
.footer-visible{
    position: absolute;
    bottom: 0;  
    width: 100%;
}

.bg-color{
    /* background-color: rgba(0, 0, 0, .2); */
}
.h-color{
    color: white;
}
.p-color{    
    color: rgb(255 255 255 / 70%);
}
.br-1{    
    border-radius: 1rem;
}
.border_bottom{
    border-bottom:solid 1px white ;
}
.border_start{
    border-left:solid 1px white ;
}
.border_right{
    border-right: 2px solid rgb(255 255 255 / 25%);
}
img,svg{
    vertical-align: middle;
}
.profile-login{
   background-color:  rgb(40, 24, 183);
}
.btn-meta-mask{
   background-color:  rgb(167, 54, 223) !important;
}
.bg-gray{
 background-color: rgb(172, 172, 172);   
}
.profile-border{
    border-radius: 5px;
    border: 1px solid white;
}
.input{
    width: 100%;
    padding: 0.5rem;
    color: white;
    border: 2px solid rgb(255 255 255 / 25%);
}
.shadow-withdraw
{
    box-shadow: 0 0 1rem #dce1df;
    border:0;
}

@media screen and (min-width:320px)
{
    .user-img{
        z-index: 99;
        width: 20px;
    }
    .treeimg{
        width: 168px;
    }
    .treeimg1{
        width: 88px;
    }
    .treeimg2{
        width: 48px;
    }
    .gap-img-1{
        width: 68px !important ;
    }
    .gap-img-2{
        width: 28px !important ;
    }
    .gap-img-3{
        width: 20px !important ;
    }
}

@media screen and (min-width:425px)
{
    .user-img{
        z-index: 99;
        width: 25px;
    }
    .treeimg{
        width: 210px;
    }
    .treeimg1{
        width: 110px;
    }
    .treeimg2{
        width: 60px;
    }
    .gap-img-1{
        width: 85px !important ;
    }
    .gap-img-2{
        width: 35px !important ;
    }
    .gap-img-3{
        width: 25px !important ;
    }
}

@media screen and (min-width:768px)
{
    .user-img{
        z-index: 99;
        width: 50px;
    }
    .treeimg{
        width: 420px;
    }
    .treeimg1{
        width: 220px;
    }
    .treeimg2{
        width: 120px;
    }
    .gap-img-1{
        width: 170px !important ;
    }
    .gap-img-2{
        width: 70px !important ;
    }
    .gap-img-3{
        width: 50px !important ;
    }
}

@media screen and (min-width:1024px)
{
    .user-img{
        z-index: 99;
        width: 50px;
    }

   

    .treeimg{
        width: 420px;
    }
    .treeimg1{
        width: 220px;
    }
    .treeimg2{
        width: 120px;
    }
    .gap-img-1{
        width: 170px !important ;
    }
    .gap-img-2{
        width: 70px !important ;
    }
    .gap-img-3{
        width: 50px !important ;
    }
}
.team-info{
    background-color: #133c4a;
    z-index: 99;
}
.bg-b{
   background-color: #013779;
   width: 100%;
}
.login_btn:hover{
    color: white !important;

}


.LoaderSpinner{
    position: fixed;
    height: 100vh;
    width: 100%;
    top:0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1000 !important;
  }

input::placeholder{
    font-size: 15px;
    color: rgb(209, 209, 209);
}
textarea::placeholder{
    font-size: 15px;
    color: rgb(209, 209, 209);
}