#about{
    max-width: 1050px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 140px auto 20px auto;
    padding-bottom: 60px;
    position: relative;
}
 
.about-img{
    max-width: 470px;
    max-height: 700px;
    width: 100%;
}
.about-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.about-text strong{
    color: #ffffff;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1.65rem;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
}
.about-text{
    width: 550px;
    position: relative;
}
.about-text h1{
    color: #ffffff;
    font-size: 2.6rem;
    line-height: 3rem;
    letter-spacing: 1px;
    font-family:  'Inter' ,'Poppins';
}
.about-text h1 span{
    color: #0C2671;
    font-family:  'Inter' ,'Poppins';
}
.about-text p{
    color: #ffffff;
    margin: 30px 0px;
    letter-spacing: 1px;
    font-size:0.9rem;
}
.about-text a,
.summery-heading a,
.dribble-btn,
.contact-btn,
.about-heading a{
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 6px;
    font-size: 0.8rem;
    padding: 10px 10px 10px 0px;
    transition: all 508ms cubic-bezier(.77, 0, .175, 1);
    background-color: #0F3380;
}
.about-text a:hover,
.summery-heading a:hover,
.dribble-btn:hover,
.btn-c a,
.contact-btn:hover,
.about-heading a:hover{
    padding: 10px;
    background-color: rgb(104, 154, 248,0.1);
    transition: all 508ms cubic-bezier(.77, 0, .175, 1);
}
.main-btns{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.main-btns a{
    width:170px;
    height: 48px; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    font-size: 0.9rem;
    letter-spacing: 1px;
    border-radius: 30px;
}
.main-btns .active-btn-header{
    background-color: #1E74B1;
    border: none;
}


@media(max-width:1010px){
    #about{
        width: 90%;
        display: flex;
        flex-direction: column;
    }
}
@media(max-width:850px){
    #about{
        padding:0px 0px 70px 0px;
        min-height: 550px;
        margin-top: 0px;
        display: flex;
        flex-direction: column;
    }
    .about-text{
        max-width: 100%;
        width: 100%;
    }
    .about-text h1{
        font-size: 2.8rem;
        line-height: 4rem;
    }
}
@media(max-width:460px){
    .about-btns{
        flex-direction: column;
        width: 100%;
    }
    .about-btns a{
        width: 100%;
        margin: 10px 0px;
    }
}
