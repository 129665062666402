.coupon{
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 40%;
}

.ddl{
width: 33.33%;
margin: 2%;
}
.Adjestment{
  font-size: 17px !important;
}


.card_main {
    background: #1488cc;
    /* fallback for old browsers */
    background-image: radial-gradient( circle farthest-corner at 83.7% 4.3%, rgb(173 0 171 / 72%) 0%, rgb(13 60 113) 90% );
    height: 50vh;
    font-family: "Josefin Sans", sans-serif;
  }

/* .coupon_number{
margin-left: -25px;
} */

  /* Background circles start */
  .circle {
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(#006db3, #29b6f6);
  }
  .circles {
    position: absolute;
    height: 270px;
    width: 450px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .circle-1 {
    height: 180px;
    width: 180px;
    top: -50px;
    left: -60px;
  }
  .circle-2 {
    height: 200px;
    width: 200px;
    bottom: -90px;
    right: -90px;
    opacity: 0.8;
  }
  /* Background circles end */

  .card-group {
    position: initial;
    /* top: 25%; */
    margin-top: 25%;
    margin-left: 61%;
    transform: translate(-50%, -50%);
  }
  @media(min-width:1024px){
    .coupn_lar{
      margin-left: 33%;
      margin-top: 3%;
      margin-bottom: -10px;
  }

  .card_main{
    margin-top: -22%;

  }
  .card {
    position: absolute;
    height: 270px;
    margin-left: -146px;
    width: 450px;
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
}

  .copn_img,
  .chip img,
  .number,
  .name,
  .from,
  .to,
  .ring {
    position: absolute; /* All items inside card should have absolute position */
  }

  .logo img {
    top: 12px;
      right: 35px;
      width: 130px;
      height: auto;
      z-index: 999999;
  }

  .chip img {
    top: 35px;
    left: 40px;
    width: 50px;
    height: auto;
    opacity: 0.8;
  }

  @media(min-width:1024px){
    #coupon_card_1{
      /* margin-top: -170px; */
      margin-top: 10%;
      /* margin-left: -10px; */
    
    }
    #coupon_card_2{
      margin-top: 10%;
       /* margin-left: -10px; */
    
    }
    #coupon_card_3{
      /* margin-top: -170px; */
      margin-top: 10%;
      /* margin-left: -10px; */
    
    }
  .number,
  .name,
  .from,
  .to {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 45px;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  }
}

  .number {
    bottom: 130px;
    font-family: "Nunito", sans-serif;
  }
  @keyframes blink {
    50% { opacity: 0; }
  }
  
  .blink {
    animation: blink 3s linear infinite;
  }

  .name {
   font-size: 40px;
   left: 178px;
   bottom: 54px;
}

  .from {
    font-size: 18px;
    bottom: 35px;
    right: 110px;
  }

  .to {
   font-size: 18px;
    bottom: 35px;
    right: 40px;
  }

  /* The two rings on the card background */
  .ring {
    height: 500px;
    width: 500px;
    border-radius: 50%;
    background: transparent;
    border: 50px solid rgba(255, 255, 255, 0.1);
    bottom: -250px;
    right: -250px;
    box-sizing: border-box;
  }

  .ring::after {
    content: "";
    position: absolute;
    height: 600px;
    width: 600px;
    border-radius: 50%;
    background: transparent;
    border: 30px solid rgba(255, 255, 255, 0.1);
    bottom: -80px;
    right: -110px;
    box-sizing: border-box;
  }

  .coupon_number{
    width: 100%;
    text-align: center;
  }

@media(max-width:1024px){
  .coupn_lar{
    margin-left: 2%;
    margin-top: 3%;
    margin-bottom: -10px;
}
  #coupon_card_1 {
    margin-top: 35px;
    margin-left: -131px;
}
#coupon_card_2 {
  margin-top: 35px;
  margin-left: -131px;
}
#coupon_card_3 {
  margin-top: 35px;
  margin-left: -131px;
}
    .card {
        position: fixed;
        height: 215px;
        width: 317px;
        border-radius: 25px;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(30px);
        border: 2px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
        overflow: hidden;
      }
      .number,
  .name,
  .from,
  .to {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 34px;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  }
  .name {
    /* font-size: 40px; */
    left: 116px;
    bottom: 25px;
}
.number {
    bottom: 80px;
    font-family: "Nunito", sans-serif;
}
.card_main {
    background: #1488cc;
    margin-top: -20%;
    background-image: radial-gradient( circle farthest-corner at 83.7% 4.3%, rgb(173 0 171 / 72%) 0%, rgb(13 60 113) 90% );
    height: 43vh;
    font-family: "Josefin Sans", sans-serif;
}

/* element.style {
}
button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
    cursor: pointer;
}
.blink {
    animation: blink 3s linear infinite;
} */
.coupon {
    margin-top: 8%;
    margin-bottom: 5%;
    margin-left: 26%;
}
}




